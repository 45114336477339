import { css } from '@emotion/react';
import styled from '@emotion/styled';
import type { EntityModels } from 'imddata';
import {
  useCreateEntity,
  useEntryProvider,
  useEntry,
  useQuery,
  useEntries,
} from 'imddata';
import { Checkbox, FieldCard, Button } from 'imdui';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MasteringStyleSelectorModal } from './MasteringStyleSelectorModal';
import { MasteringTrackAudio } from './MasteringTrackAudio';

const VersionsList = styled.div`
  width: 100%;
  display: grid;
  gap: 8px;
`;

const SelectRow = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`;

const MasteringTrackAudioConnected = ({
  masteringTrackId,
  trackId,
  onSelect,
  selected,
  masteringAudioReferenceId,
}: {
  onSelect?: (fileId: string, checked: boolean) => void;
  selected?: string[];
  trackId: string | number;
  masteringAudioReferenceId: string;
  masteringTrackId?: string;
}) => {
  const { t } = useTranslation();
  const [id, setId] = useState<undefined | string>(masteringTrackId);
  const { createdId, createEntry } = useCreateEntity({
    entity: 'masteringTracks',
  });
  useEffect(() => {
    if (createdId) {
      setId(createdId as string);
    }
  }, [createdId]);
  useEffect(() => {
    if (!masteringTrackId) {
      createEntry({
        data: {
          trackId: trackId,
          masteringReferenceAudioId: masteringAudioReferenceId,
        },
      });
    }
  }, []);
  const masteredTrackPreview = useEntryProvider<EntityModels.MasteringTrack>({
    entity: 'masteringTracks',
    id,
  });
  const { entry: previewFile } = useEntry<EntityModels.File>({
    entity: 'files',
    id: masteredTrackPreview?.previewFileId,
    passive: !masteredTrackPreview,
  });
  const masteringStyleReference =
    useEntryProvider<EntityModels.MasteringReferenceAudio>({
      entity: 'masteringReferenceAudios',
      id: masteringAudioReferenceId,
    });
  if (!masteringStyleReference) return null;

  // Check for download URL, because file is registered before URL is ready
  const loading = !masteredTrackPreview || !previewFile?.downloadUrl;

  const title = loading
    ? t('generating-mastering-in-style', {
      defaultValue: 'Mastering in {{style}} style',
      style: masteringStyleReference.customerId
        ? t('reference-file')
        : t(masteringStyleReference.label),
    })
    : t('mastered-in-style', {
      defaultValue: 'Mastered in {{style}} style',
      style: masteringStyleReference.customerId
        ? t('reference-file')
        : t(masteringStyleReference.label),
    });

  return (
    <>
      {onSelect && masteredTrackPreview && (
        <>
          <Checkbox
            name={`version-${masteredTrackPreview.id}`}
            disabled={loading}
            onCheck={(e, checked) => onSelect(masteredTrackPreview.id, checked)}
            checked={selected?.includes(masteredTrackPreview.id)}
          />
        </>
      )}
      <MasteringTrackAudio
        fileUrl={previewFile?.downloadUrl}
        trackId={trackId}
        primary={true}
        loading={loading}
        title={title}
        subtitle={
          !masteringStyleReference.customerId
            ? undefined
            : masteringStyleReference.label
        }
      />
    </>
  );
};

export const MasteringTrackVersions = ({
  originalAudioId,
  version,
  onSelect,
  trackId,
  style,
  className,
  selected,
}: {
  style?: React.CSSProperties;
  className?: string;
  originalAudioId: any;
  trackId: string | number;
  onSelect?: (fileId: string, checked: boolean) => void;
  selected?: string[];
  version?: string;
}) => {
  // TODO fetch existing version to populate array first
  const { t } = useTranslation();
  const [versions, setVersions] = useState<
    { masteringTrackId?: string; masteringAudioReferenceId: string }[]
  >([]);
  const [openStyleSelector, setOpenStyleSelector] = useState(false);
  const { query, queryHash } = useQuery({
    query: {
      'filter.original_files': originalAudioId,
    },
  });
  const {
    entries: storedVersions,
    request: { loaded: loadedStoredVersions },
  } = useEntries<EntityModels.MasteringTrack>({
    entity: 'masteringTracks',
    query,
    queryHash,
  });

  useEffect(() => {
    if (
      version &&
      !versions.find((v) => v.masteringAudioReferenceId === version)
    ) {
      setVersions((s) => [...s, { masteringAudioReferenceId: version }]);
    }
  }, [version]);

  useEffect(() => {
    if (loadedStoredVersions && storedVersions) {
      setVersions((state) => [
        ...state,
        ...storedVersions
          .filter((sv) => {
            if (
              versions.find(
                (v) =>
                  v.masteringAudioReferenceId === sv.masteringReferenceAudioId
              )
            )
              return false;

            // Do not show paid versions
            if (
              sv.status === 'payment_pending' ||
              sv.status === 'paid' ||
              sv.status === 'mastered'
            ) {
              return false;
            }
            return true;
          })
          .map((sv) => ({
            masteringAudioReferenceId: sv.masteringReferenceAudioId,
            masteringTrackId: sv.id,
          })),
      ]);
    }
  }, [loadedStoredVersions]);

  return (
    <FieldCard
      style={style}
      className={className}
      css={css`
        gap: 24px;
      `}
    >
      <VersionsList>
        <SelectRow>
          {onSelect && (
            <Checkbox
              name=""
              checked
              style={{ visibility: 'hidden' }}
              onCheck={() => {
                //
              }}
            />
          )}
          <MasteringTrackAudio
            primary={false}
            trackId={trackId}
            title={t('original')}
          />
        </SelectRow>

        {versions.map((version, index) => (
          <SelectRow key={index}>
            <MasteringTrackAudioConnected
              selected={selected}
              onSelect={onSelect}
              trackId={trackId}
              masteringAudioReferenceId={version.masteringAudioReferenceId}
              masteringTrackId={version.masteringTrackId}
            />
          </SelectRow>
        ))}
      </VersionsList>

      <MasteringStyleSelectorModal
        created={versions}
        onSelect={(style) => {
          if (
            style &&
            !versions.find((v) => v.masteringAudioReferenceId === style)
          ) {
            setVersions((s) => [...s, { masteringAudioReferenceId: style }]);
          }
          setOpenStyleSelector(false);
        }}
        open={openStyleSelector}
        onRequestClose={() => {
          setOpenStyleSelector(false);
        }}
      />
      {!onSelect && (
        <Button
          style={{ width: '100%' }}
          text={t('add-version')}
          onClick={() => {
            setOpenStyleSelector(true);
          }}
        />
      )}
    </FieldCard>
  );
};
