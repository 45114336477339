import styled from '@emotion/styled';
import { ModalWindowTitle } from '@imus/base-ui';
import { BuyOrSubOffer } from 'imdshared';
import {
  Button,
  FieldCard,
  IconButton,
  OverlineText,
  Radio,
  Window,
} from 'imdui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MasteringTrackAudio } from './MasteringTrackAudio';
import type { EntityModels } from 'imddata';
import {
  useCustomerFeature,
  useEntries,
  useEntry,
  useSubscriptionLimit,
} from 'imddata';
import { MasteringReferenceAudioUploader } from './MasteringCustomReferenceUploader';

const ButtonGroup = styled.div`
  display: flex;
  border-radius: 24px;
  background: var(--surface-container-low, #1d1d1d);
  gap: 4px;
  padding: 4px;

  align-items: center;
  align-self: stretch;
  & > * {
    flex: 1;
  }
`;

const Tabs = styled.div`
  margin: 0 32px 16px;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 488px;
  overflow-y: auto;
  padding: 16px 32px;
  flex-direction: column;
  gap: 32px;
  // border-top: 1px solid var(--outline-var);
  // border-bottom: 1px solid var(--outline-var);
`;
const Chips = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
`;

const Footer = styled.div`
  display: flex;
  padding: 16px 32px 32px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  & > * {
    flex: 1;
  }
`;

const SubscribeUpsellStyled = styled(BuyOrSubOffer)`
  margin-bottom: 0px;
  width: 100%;
`;

const SelectableTrackRow = styled.div`
  display: flex;
  height: 40px;
  width: 100%;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

const Divider = styled.div`
  width: 100%;
  padding: 24px 0 0;
`;

export const MasteringReferenceAudioConnected = ({
  referenceAudio,
}: {
  referenceAudio: EntityModels.CustomMasteringReferenceAudio;
}) => {
  const { entry: previewFile } = useEntry<EntityModels.File>({
    entity: 'files',
    id: referenceAudio.fileId,
  });
  return (
    <MasteringTrackAudio
      style={{ flex: 1 }}
      // TODO this is a hack to make player work
      trackId={referenceAudio.id}
      title={referenceAudio.label}
      fileUrl={previewFile?.downloadUrl}
    />
  );
};

export const MasteringStyleSelectorModal = ({
  open,
  onSelect,
  created,
  onRequestClose,
}: {
  created?: { masteringAudioReferenceId: string }[];
  open: boolean;
  onRequestClose: () => void;
  onSelect: (style: string) => void;
}) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<string | null>(null);
  const [mode, setMode] = useState<'genre' | 'reference'>('genre');
  const freeMasteringLimit = useSubscriptionLimit('free_mastering_usage');
  const hasReferenceMastering = useCustomerFeature('reference-mastering');
  useEffect(() => {
    setSelected(null);
  }, [mode]);
  const { entries: masteringReferenceAudios, refresh } =
    useEntries<EntityModels.MasteringReferenceAudio>({
      entity: 'masteringReferenceAudios',
    });

  const genres = masteringReferenceAudios.filter(
    (mfa) => mfa.customerId === null
  );

  const custom = masteringReferenceAudios.filter(
    (mfa) => mfa.customerId !== null
  );

  const isSelected = (id: string) =>
    created?.some((c) => c.masteringAudioReferenceId === id);
  return (
    <Window
      style={{ maxWidth: '512px' }}
      close={onRequestClose}
      isOpen={open}
      title={
        <>
          <ModalWindowTitle>{t('choose-mastering-style')}</ModalWindowTitle>
        </>
      }
    >
      <Tabs>
        <ButtonGroup>
          <Button
            appearance={mode === 'genre' ? 'elevated' : 'transparent'}
            text={t('use-genre')}
            onClick={() => setMode('genre')}
          ></Button>
          <Button
            appearance={mode === 'reference' ? 'elevated' : 'transparent'}
            text={t('use-reference')}
            onClick={() => setMode('reference')}
          ></Button>
        </ButtonGroup>
      </Tabs>
      <Wrapper>
        {mode === 'genre' && (
          <>
            {!freeMasteringLimit && (
              <SubscribeUpsellStyled
                title={t('instant-mastering-upsell-title')}
                description={t('instant-mastering-upsell-description')}
              ></SubscribeUpsellStyled>
            )}
            <Chips>
              {genres.map((g) => {
                return (
                  <Button
                    key={g.id}
                    appearance="stroke"
                    disabled={isSelected(g.id)}
                    primary={
                      selected === g.id || isSelected(g.id) ? true : undefined
                    }
                    text={t(g.label)}
                    onClick={() => {
                      setSelected(g.id);
                    }}
                  />
                );
              })}
            </Chips>
          </>
        )}
        {mode === 'reference' && (
          <>
            {!hasReferenceMastering && (
              <SubscribeUpsellStyled
                title={t('instant-mastering-reference-upsell-title')}
                description={t(
                  'instant-mastering-reference-upsell-description'
                )}
              ></SubscribeUpsellStyled>
            )}
            <div>
              <OverlineText label={t('choose-reference-track')} />
              <FieldCard style={{ padding: '24px' }}>
                {custom.map((file) => (
                  <SelectableTrackRow key={file.id}>
                    <IconButton
                      disabled={isSelected(file.id)}
                      inline
                      onClick={() => {
                        setSelected(file.id);
                      }}
                    >
                      <Radio
                        selected={isSelected(file.id) || selected === file.id}
                      ></Radio>
                    </IconButton>
                    <MasteringReferenceAudioConnected referenceAudio={file} />
                  </SelectableTrackRow>
                ))}
                {custom.length > 0 && <Divider />}
                <MasteringReferenceAudioUploader
                  onUploaded={() => {
                    refresh();
                  }}
                />
              </FieldCard>
            </div>
          </>
        )}
      </Wrapper>
      <Footer>
        <Button
          text={t('cancel')}
          onClick={() => {
            onRequestClose();
          }}
          size="large"
          appearance="elevated"
        />
        <Button
          text={t('generate-preview')}
          disabled={!selected}
          onClick={() => {
            if (selected) {
              onSelect(selected);
            }
          }}
          size="large"
          primary={!!selected}
          appearance="fill"
        />
      </Footer>
    </Window>
  );
};

export const MasteringVersionButtonSelect = ({
  onAddVersion,
}: {
  onAddVersion: (version: string) => void;
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <MasteringStyleSelectorModal
        open={open}
        onRequestClose={() => setOpen(false)}
        onSelect={onAddVersion}
      />
      <Button text={t('add-version')} onClick={() => setOpen(true)} />
    </>
  );
};
