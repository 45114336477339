import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import type { AppLocale } from 'imddata';
import { ImpersonateContext, useCurrentUser } from 'imddata';
import ButtonDropdown from '../ButtonDropdown';
import { updateLanguage } from '@imus/uis-integration';

const options: Array<{ label: string; value: AppLocale }> = [
  { label: 'Deutsch', value: 'de' },
  { label: 'English', value: 'en' },
  { label: 'Español', value: 'es' },
  { label: 'Français', value: 'fr' },
  { label: 'Italiano', value: 'it' },
  { label: 'Português', value: 'pt' },
];

type Props = {
  className?: string;
  value?: string;
  onChange?: (value: string) => void;
  style?: any;
};

export const useCurrentLocale = (): AppLocale => {
  const { i18n } = useTranslation();

  const [, language] = i18n.language.match(/(.*)-.*/) || [
    i18n.language,
    i18n.language,
  ];

  const isAvaialable = options.find(({ value }) => value === language);

  return isAvaialable ? (language as AppLocale) : 'en';
};

const LanguageSwitcher: React.FC<Props> = ({
  className,
  onChange,
  value,
  style,
  // TODO: drop ButtonDropdown API & component renderer
  // @ts-ignore
  component: Component = ButtonDropdown,
  ...props
}) => {
  const { i18n } = useTranslation();
  const { entry } = useCurrentUser();
  const locale = useCurrentLocale();
  const impersonate = useContext(ImpersonateContext);

  const handleLanguageChange = useCallback((data: any) => {
    if (onChange) {
      onChange(data);
      return;
    }
    i18n.changeLanguage(data);
    if (entry && !impersonate.userId) {
      updateLanguage(data)
    }
  }, []);

  return (
    <Component
      className={className}
      style={style}
      data={options}
      value={value || locale}
      onChange={handleLanguageChange}
      {...props}
    />
  );
};

export default LanguageSwitcher;
