const UIS_ENDPOINT = process.env.UIS_ENDPOINT || '';

function fetchAPI(endpoint: string, options: RequestInit) {
  return fetch(endpoint, options).then((response) => {
    return response
      .json()
      .then((json) => {
        return {
          json,
          response,
        };
      })
      .catch((error) => {
        return { error, response, json: {} };
      });
  });
}

export function getCurrentSubscription(trackingId: string) {
  const fetchOptions = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'GET',
  };
  return fetchAPI(`${UIS_ENDPOINT}/subscriptions/${trackingId}`, fetchOptions);
}

export function updateLanguage(languageId: string) {
  const fetchOptions: RequestInit = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({ lang: languageId }),
    credentials: 'include',
  };
  return fetchAPI(`${UIS_ENDPOINT}/lang`, fetchOptions);
}

export function setNewsletterLists(
  trackingId: string,
  email: string,
  subscribeList: string[],
  unsubscribeList: string[]
) {
  const fetchOptions = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      tracking_id: trackingId,
      email,
      subscribe_lists: subscribeList,
      unsubscribe_lists: unsubscribeList,
    }),
  };
  const endpoint = `${UIS_ENDPOINT}/identify`;
  return fetchAPI(endpoint, fetchOptions);
}
